import { Inject, Injectable } from '@angular/core';
import { OktaAuth, UserClaims } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { TokenManagerError } from '@okta/okta-auth-js/types/lib/oidc/types/TokenManager';

@Injectable({
  providedIn: 'root',
})
export class AuthorityService {
  private userInfoSubject: BehaviorSubject<UserClaims | null> =
      new BehaviorSubject<UserClaims | null>(null);

  constructor(
    @Inject(OKTA_AUTH) private readonly oktaAuth: OktaAuth,
    private readonly router: Router,
  ) {
    this.oktaAuth.tokenManager.on('renewed', (key, newToken) => {
      this.getUserInfo(); //Update user
    });
    this.oktaAuth.tokenManager.on('error', (err: TokenManagerError) => {
      if (err.name === 'OAuthError') {
        this.oktaAuth.signInWithRedirect().catch(error => {
          if (error.error === 'access_denied') {
            this.router.navigate(['/access-error']);
          }
        });
      }
    });
  }

  async getUserScopesByAccessToken(): Promise<string[]> {
    let scopes: string[] = [];
    const tokens = await this.oktaAuth.tokenManager.getTokens();
    if (tokens?.accessToken) {
      scopes = (tokens.accessToken.scopes || []);
    }
    return scopes;
  }

  async getUserScopes(): Promise<string[]> {
    let scopes: string[] = [];
    const tokens = await this.oktaAuth.tokenManager.getTokens();
    if (tokens?.idToken) {
      const oasRequiredPermissions = (tokens.idToken.claims['oasRequiredPermissions'] || []);
      if (Array.isArray(oasRequiredPermissions)) {
        scopes = (oasRequiredPermissions as string[]).filter(item => item && item.trim() !== '');
      }
    }
    return scopes;
  }

  async hasScopes(requiredScopes: string[]): Promise<boolean> {
    let scopes: string[] = await this.getUserScopesByAccessToken();
    return requiredScopes.some(requiredScope => scopes.includes(requiredScope));
  }

  async getUserInfo() {
    const tokens = await this.oktaAuth.tokenManager.getTokens();
    if (tokens.idToken) {
      const decodedToken: UserClaims = jwtDecode(tokens.idToken.idToken);

      this.userInfoSubject.next(decodedToken);
    }
  }

}
