import {CanActivateFn, Router} from '@angular/router';
import { inject } from '@angular/core';
import { AuthorityService } from "../services/authority.service";

export const authorityGuard: CanActivateFn = async (route, state) => {
  const authorityService = inject(AuthorityService);
  const router = inject(Router); // Injecte le Router pour la redirection

  const requiredScopes = route.data['requiredScopes'];
  const hasScopes = await authorityService.hasScopes(requiredScopes);

  if (hasScopes) {
    return true;
  } else {
    await router.navigate(['/access-error']);
    return false;
  }
};
