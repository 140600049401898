import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessErrorComponent } from './shared/composants/errors/access-error/access-error.component';
import { moduleGuard } from './core/guard/module.guard';
import { Modules } from './shared/utils/logistique-pn';
import { authenticatedGuard } from "./core/guard/authenticated.guard";
import {authorityGuard} from "./core/guard/authority.guard";
import {Scopes} from "./core/const/scopes";

const routes: Routes = [
  {
    path: '',
    redirectTo: `/home`,
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [authenticatedGuard],
  },
  {
    path: 'access-error',
    component: AccessErrorComponent,
  },
  {
    path: 'shuttle',
    loadChildren: () =>
      import('./modules/shuttle/shuttle.module').then(m => m.ShuttleModule),
    canActivate: [moduleGuard, authenticatedGuard, authorityGuard],
    data: { moduleName: Modules.Shuttle, requiredScopes: [Scopes.SHUTTLES_WRITE, Scopes.SHUTTLES_READ] },
  },
  {
    path: 'taxi-and-pickup',
    loadChildren: () =>
      import('./modules/taxi/taxi.module').then(m => m.TaxiModule),
    canActivate: [moduleGuard, authenticatedGuard, authorityGuard],
    data: { moduleName: Modules.TaxiAndPickup, requiredScopes: [Scopes.TAXIS_WRITE, Scopes.TAXIS_READ] },
  },
  {
    path: 'meal-tray',
    loadChildren: () =>
      import('./modules/meal-tray/meal-tray.module').then(
        m => m.MealTrayModule
      ),
    canActivate: [moduleGuard, authenticatedGuard, authorityGuard],
    data: { moduleName: Modules.MealTray, requiredScopes: [Scopes.PEQ_WRITE, Scopes.PEQ_READ] },
  },
  {
    path: 'compensation',
    loadChildren: () =>
      import('./modules/compensation/compensation.module').then(
        m => m.CompensationModule
      ),
    canActivate: [moduleGuard, authenticatedGuard, authorityGuard],
    data: { moduleName: Modules.Compensation, requiredScopes: [Scopes.MEAL_COMPENSATION_WRITE, Scopes.MEAL_COMPENSATION_READ] },
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [moduleGuard, authenticatedGuard, authorityGuard],
    data: { moduleName: Modules.Settings, requiredScopes: [Scopes.PARAMETERS_WRITE] },
  },
  {
    path: '**',
    redirectTo: `/home`,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
