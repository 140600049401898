export class Scopes {

    public static readonly PEQ_READ = 'oas:iop:logpn:peq_read';
    public static readonly PEQ_WRITE = 'oas:iop:logpn:peq_write';
    public static readonly MEAL_COMPENSATION_READ = 'oas:iop:logpn:meal_compensation_read';
    public static readonly MEAL_COMPENSATION_WRITE = 'oas:iop:logpn:meal_compensation_write';
    public static readonly TAXIS_READ = 'oas:iop:logpn:taxis_read';
    public static readonly TAXIS_WRITE = 'oas:iop:logpn:taxis_write';
    public static readonly SHUTTLES_READ = 'oas:iop:logpn:shuttles_read';
    public static readonly SHUTTLES_WRITE = 'oas:iop:logpn:shuttles_write';
    public static readonly PARAMETERS_WRITE = 'oas:iop:logpn:parameters_write';

}