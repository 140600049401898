<form [formGroup]="form" class="form-content">
  <div class="form-grid">
    @for (field of fieldsDefinition; track field.name) {
      <div class="field-content" [ngClass]="field.ngClass">
        @switch (field.type) {
          @case ('icon') {
            <icon [type]="field.name"></icon>
          }
          @case ('text') {
            <logpn-input-text
              [label]="field.label"
              [formControlName]="field.name"></logpn-input-text>
          }
          @case ('date') {
            <logpn-input-date
              [label]="field.label"
              [formControlName]="field.name"></logpn-input-date>
          }
          @case ('list') {
            <logpn-input-list
              [label]="field.label"
              [formControlName]="field.name"
              [options]="field.options"></logpn-input-list>
          }
          @case ('chips') {
            <logpn-input-chip
              [label]="field.label"
              [formControlName]="field.name"></logpn-input-chip>
          }
        }
      </div>
    }
  </div>
  <div class="form-actions">
    <button type="button" (click)="onCancel()" class="button-cancel">
      {{ 'forms.cancel' | transloco }}
    </button>
    <button
      type="button"
      (click)="onSubmit()"
      [disabled]="form.invalid"
      class="button-save">
      {{ 'forms.save' | transloco }}
    </button>
  </div>
</form>
