<div class="flex-column">
  <label for="input" [ngClass]="{ required: required }">
    {{ label | transloco }}
  </label>
  <mat-form-field class="example-form-field">
    <mat-chip-grid
      #reactiveChipGrid
      id="input"
      aria-label="Enter reactive form keywords"
      [formControl]="control">
      @for (value of control?.value; track value) {
        <mat-chip-row (removed)="removeReactiveKeyword(value)">
          {{ value }}
          <button matChipRemove aria-label="'remove reactive form' + keyword">
            <icon type="cross-xs"></icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-grid>
    <input
      [matChipInputFor]="reactiveChipGrid"
      (matChipInputTokenEnd)="addValue($event)" />
  </mat-form-field>
</div>
