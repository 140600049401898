<nav role="navigation" class="sidenav">
    <button
            class="button-item"
            mat-menu-item
            (click)="selectMenuItem('home')"
            [ngClass]="{ selected: selectedMenuItem === 'home' }">
        <mat-icon
                class="icon-item material-icons material-icons--rounded"
                aria-labelledby="nav-home"
        >home
        </mat-icon
        >
        <span id="nav-home" class="title-item" *ngIf="isSidenavOpen">{{
                'sidenav.home' | transloco
            }}</span>
    </button>
    <div class="separator"></div>
    <div class="container-separator-title">
        <div class="separator-title" *ngIf="isSidenavOpen">
            {{ 'sidenav.orders' | transloco }}
        </div>
    </div>
    @if (moduleService.isModuleEnabled(Modules.Hotel)) {
        <button
                class="button-item"
                mat-menu-item
                [disabled]="true"
                (click)="selectMenuItem('hotel')"
                [ngClass]="{ selected: selectedMenuItem === 'hotel' }">
            <mat-icon
                    matBadge="5"
                    matBadgeColor="warn"
                    class="icon-item"
                    aria-labelledby="nav-hotel"
            >hotel
            </mat-icon
            >
            <span id="nav-hotel" *ngIf="isSidenavOpen" class="title-item">{{
                    'sidenav.hotel' | transloco
                }}</span>
        </button>
    }
    @if (moduleService.isModuleEnabled(Modules.MepPlane)) {
        <button
                [disabled]="true"
                class="button-item"
                mat-menu-item
                (click)="selectMenuItem('mepPlane')"
                [ngClass]="{ selected: selectedMenuItem === 'mepPlane' }">
            <mat-icon class="icon-item" aria-labelledby="nav-mep-plane"
            >airplane_mode
            </mat-icon
            >
            <span id="nav-mep-plane" *ngIf="isSidenavOpen" class="title-item">{{
                    'sidenav.mepPlane' | transloco
                }}</span>
        </button>
    }
    @if (moduleService.isModuleEnabled(Modules.Shuttle) && hasShuttlesScope) {
        <button
                class="button-item"
                mat-menu-item
                (click)="selectMenuItem('shuttle')"
                [ngClass]="{ selected: selectedMenuItem === 'shuttle' }">
            <mat-icon class="icon-item" aria-labelledby="nav-runway-airport-shuttle"
            >airport_shuttle
            </mat-icon
            >
            <span
                    id="nav-runway-airport-shuttle"
                    *ngIf="isSidenavOpen"
                    class="title-item"
            >{{ 'sidenav.runwayAndAirportShuttle' | transloco }}</span
            >
        </button>
    }
    @if (moduleService.isModuleEnabled(Modules.TaxiAndPickup) && hasTaxisScope) {
        <button
                class="button-item"
                mat-menu-item
                (click)="selectMenuItem('taxi-and-pickup')"
                [ngClass]="{ selected: selectedMenuItem === 'taxi-and-pickup' }">
            <mat-icon class="icon-item" aria-labelledby="nav-cab-pickup"
            >local_taxi
            </mat-icon
            >
            <span id="nav-cab-pickup" *ngIf="isSidenavOpen" class="title-item">{{
                    'sidenav.cabAndPickup' | transloco
                }}</span>
        </button>
    }
    @if (moduleService.isModuleEnabled(Modules.CarRentralAndVDS)) {
        <button
                [disabled]="true"
                class="button-item"
                mat-menu-item
                (click)="selectMenuItem('rentalCarAndVDS')"
                [ngClass]="{ selected: selectedMenuItem === 'rentalCarAndVDS' }">
            <mat-icon
                    matBadge="16"
                    matBadgeColor="warn"
                    class="icon-item"
                    aria-labelledby="nav-rental-car-vds"
            >home
            </mat-icon>
            <span id="nav-rental-car-vds" *ngIf="isSidenavOpen" class="title-item">{{
                    'sidenav.rentalCarAndVDS' | transloco
                }}</span>
        </button>
    }
    @if (moduleService.isModuleEnabled(Modules.Train)) {
        <button
                [disabled]="true"
                class="button-item"
                mat-menu-item
                (click)="selectMenuItem('train')"
                [ngClass]="{ selected: selectedMenuItem === 'train' }">
            <mat-icon
                    matBadge="1"
                    matBadgeColor="warn"
                    class="icon-item"
                    aria-labelledby="nav-train"
            >train
            </mat-icon
            >
            <span id="nav-train" *ngIf="isSidenavOpen" class="title-item">{{
                    'sidenav.train' | transloco
                }}</span>
        </button>
    }
    @if (moduleService.isModuleEnabled(Modules.MealTray) && hasPeqScope) {
        <button
                class="button-item"
                mat-menu-item
                (click)="selectMenuItem('meal-tray')"
                [ngClass]="{ selected: selectedMenuItem === 'meal-tray' }">
            <mat-icon class="icon-item" aria-labelledby="nav-meal"
            >restaurant_menu
            </mat-icon
            >
            <span id="nav-meal" *ngIf="isSidenavOpen" class="title-item">{{
                    'sidenav.meal' | transloco
                }}</span>
        </button>
    }
    @if (moduleService.isModuleEnabled(Modules.Compensation) && hasMealCompensationScope) {
        <button
                class="button-item"
                mat-menu-item
                (click)="selectMenuItem('compensation')"
                [ngClass]="{ selected: selectedMenuItem === 'compensation' }">
            <mat-icon class="icon-item" aria-labelledby="nav-compensation"
            >euro_symbol
            </mat-icon
            >
            <span id="nav-compensation" *ngIf="isSidenavOpen" class="title-item">{{
                    'sidenav.compensation' | transloco
                }}</span>
        </button>
    }
    @if (moduleService.isModuleEnabled(Modules.CalculatingPoint) ||
    moduleService.isModuleEnabled(Modules.History)) {
        <div class="separator"></div>
        <div class="container-separator-title">
            <div class="separator-title" *ngIf="isSidenavOpen">
                {{ 'sidenav.logisticsPoints' | transloco }}
            </div>
        </div>
        @if (moduleService.isModuleEnabled(Modules.CalculatingPoint)) {
            <button
                    [disabled]="true"
                    class="button-item"
                    mat-menu-item
                    (click)="selectMenuItem('calculatingPoints')"
                    [ngClass]="{ selected: selectedMenuItem === 'calculatingPoints' }">
                <mat-icon class="icon-item" aria-labelledby="nav-calculating-points"
                >calculate
                </mat-icon
                >
                <span
                        id="nav-calculating-points"
                        *ngIf="isSidenavOpen"
                        class="title-item"
                >{{ 'sidenav.calculatingPoints' | transloco }}</span
                >
            </button>
        }
        @if (moduleService.isModuleEnabled(Modules.History)) {
            <button
                    [disabled]="true"
                    class="button-item"
                    mat-menu-item
                    (click)="selectMenuItem('history')"
                    [ngClass]="{ selected: selectedMenuItem === 'history' }">
                <mat-icon class="icon-item" aria-labelledby="nav-history"
                >history
                </mat-icon
                >
                <span id="nav-history" *ngIf="isSidenavOpen" class="title-item">{{
                        'sidenav.history' | transloco
                    }}</span>
            </button>
        }
    }
    @if (moduleService.isModuleEnabled(Modules.Settings) && hasParametersScope) {
        <div class="separator"></div>
        <button
                class="button-item"
                mat-menu-item
                (click)="selectMenuItem('settings')"
                [ngClass]="{ selected: selectedMenuItem === 'settings' }">
            <mat-icon class="icon-item" aria-labelledby="nav-settings"
            >settings
            </mat-icon
            >
            <span id="nav-settings" *ngIf="isSidenavOpen" class="title-item">{{
                    'sidenav.settings' | transloco
                }}</span>
        </button>
    }
</nav>
