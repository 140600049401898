import {Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {SidenavService} from '../../services/sidenav.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ModuleService} from '../../../core/services/module.service';
import {Modules} from '../../utils/logistique-pn';
import {AuthorityService} from "../../../core/services/authority.service";
import {Scopes} from "../../../core/const/scopes";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
})
export class SidenavComponent {
  selectedMenuItem: string | null = 'home';
  isSidenavOpen: boolean = true;
  private sidenavSubscription: Subscription;

  hasPeqScope: boolean = false;
  hasMealCompensationScope: boolean = false;
  hasTaxisScope: boolean = false;
  hasShuttlesScope: boolean = false;
  hasParametersScope: boolean = false;

  constructor(
    private sidenavService: SidenavService,
    private route: ActivatedRoute,
    private router: Router,
    protected moduleService: ModuleService,
    protected authorityService: AuthorityService
  ) {
    const currentUrl = this.router.url;
    const segments = currentUrl.split('/');
    if (segments.length > 1) {
      this.selectedMenuItem = segments[1];
    }
    this.sidenavSubscription = this.sidenavService.isSidenavOpen$.subscribe(
      isOpen => {
        this.isSidenavOpen = isOpen;
      }
    );
  }

  ngOnInit() {
    const currentUrl = this.router.url;
    const segments = currentUrl.split('/');
    if (segments.length > 1) {
      this.selectedMenuItem = segments[1];
    }
    this.checkScopes();
  }

  ngOnDestroy() {
    this.sidenavSubscription.unsubscribe();
  }

  selectMenuItem(menuItem: string) {
    this.selectedMenuItem = menuItem;
    this.router.navigate([menuItem], { relativeTo: this.route.parent });
  }

  protected readonly Modules = Modules;
  protected readonly History = History;
  protected readonly Scopes = Scopes;

  async checkScopes(): Promise<void> {
    this.hasPeqScope = await this.authorityService.hasScopes([Scopes.PEQ_WRITE, Scopes.PEQ_READ]);
    this.hasMealCompensationScope = await this.authorityService.hasScopes([Scopes.MEAL_COMPENSATION_READ, Scopes.MEAL_COMPENSATION_WRITE]);
    this.hasTaxisScope = await this.authorityService.hasScopes([Scopes.TAXIS_WRITE, Scopes.TAXIS_READ]);
    this.hasShuttlesScope = await this.authorityService.hasScopes([Scopes.SHUTTLES_WRITE, Scopes.SHUTTLES_READ]);
    this.hasParametersScope = await this.authorityService.hasScopes([Scopes.PARAMETERS_WRITE]);
  }
}
