import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';
import { RouterModule } from '@angular/router';
import {
  OKTA_CONFIG,
  OktaAuthModule,
  OktaCallbackComponent,
} from '@okta/okta-angular';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { AccessErrorComponent } from './shared/composants/errors/access-error/access-error.component';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from './shared/utils/CustomPaginatorConfiguration';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { LoaderInterceptor } from './core/interceptors/loader-interceptor.interceptor';

registerLocaleData(localeFr, 'fr');

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const oktaConfig = {
  issuer: environment.okta.issuer,
  clientId: environment.okta.clientId,
  redirectUri: environment.okta.redirectUri,
  scopes: ['openid', 'profile', 'email', 'required_permissions', 'offline_access'],
  pkce: true,
};

const oktaAuth = new OktaAuth(<OktaAuthOptions>oktaConfig);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot([
      { path: 'callback', component: OktaCallbackComponent },
      { path: 'access-error', component: AccessErrorComponent },
    ]),
    OktaAuthModule,
  ],
  providers: [
    provideAnimationsAsync(),
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }, // Set calendar locale to French
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: OKTA_CONFIG, useValue: { oktaAuth } }, //redirect to okta
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, //add token in requests
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }, // Loader
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
