<ng-container [ngSwitch]="type">
  <!-- aircraft -->
  <ng-container *ngSwitchCase="'aircraft-sm'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5 16V14L13.5 9V3.5C13.5 2.67 12.83 2 12 2C11.17 2 10.5 2.67 10.5 3.5V9L2.5 14V16L10.5 13.5V19L8.5 20.5V22L12 21L15.5 22V20.5L13.5 19V13.5L21.5 16Z"
        fill="black"
        fill-opacity="0.58" />
    </svg>
  </ng-container>

  <!-- arrow left -->
  <ng-container *ngSwitchCase="'plus-xs'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M8.66634 4.66683H7.33301V7.3335H4.66634V8.66683H7.33301V11.3335H8.66634V8.66683H11.333V7.3335H8.66634V4.66683ZM7.99967 1.3335C4.32634 1.3335 1.33301 4.32683 1.33301 8.00016C1.33301 11.6735 4.32634 14.6668 7.99967 14.6668C11.673 14.6668 14.6663 11.6735 14.6663 8.00016C14.6663 4.32683 11.673 1.3335 7.99967 1.3335ZM7.99967 13.3335C5.05967 13.3335 2.66634 10.9402 2.66634 8.00016C2.66634 5.06016 5.05967 2.66683 7.99967 2.66683C10.9397 2.66683 13.333 5.06016 13.333 8.00016C13.333 10.9402 10.9397 13.3335 7.99967 13.3335Z"
        fill="#051039" />
    </svg>
  </ng-container>

  <!-- arrow left -->
  <ng-container *ngSwitchCase="'arrow-left-xs'">
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      [attr.aria-label]="'icons.arrow' | transloco">
      <path
        d="M16 7.5H3.83L9.42 1.91L8 0.5L0 8.5L8 16.5L9.41 15.09L3.83 9.5H16V7.5Z"
        fill="#C40000" />
    </svg>
  </ng-container>

  <!-- arrow right -->
  <ng-container *ngSwitchCase="'arrow-right-xs'">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      [attr.aria-label]="'icons.arrow' | transloco">
      <path
        d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"
        fill="currentColor" />
    </svg>
  </ng-container>

  <!-- arrow up / down -->
  <ng-container *ngSwitchCase="'arrow-up-down-sm'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 17.01V10H14V17.01H11L15 21L19 17.01H16ZM9 3L5 6.99H8V14H10V6.99H13L9 3ZM16 17.01V10H14V17.01H11L15 21L19 17.01H16ZM9 3L5 6.99H8V14H10V6.99H13L9 3Z"
        fill="black"
        fill-opacity="0.58" />
    </svg>
  </ng-container>

  <!-- bed -->
  <ng-container *ngSwitchCase="'bed-sm'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 13.5C8.66 13.5 10 12.16 10 10.5C10 8.84 8.66 7.5 7 7.5C5.34 7.5 4 8.84 4 10.5C4 12.16 5.34 13.5 7 13.5ZM7 9.5C7.55 9.5 8 9.95 8 10.5C8 11.05 7.55 11.5 7 11.5C6.45 11.5 6 11.05 6 10.5C6 9.95 6.45 9.5 7 9.5ZM19 6.5H11V14.5H3V4.5H1V19.5H3V16.5H21V19.5H23V10.5C23 8.29 21.21 6.5 19 6.5ZM21 14.5H13V8.5H19C20.1 8.5 21 9.4 21 10.5V14.5Z"
        fill="#051039" />
    </svg>
  </ng-container>

  <!-- breakfast -->
  <ng-container *ngSwitchCase="'breakfast-sm'">
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      [attr.aria-label]="'icons.breakfast' | transloco">
      <title>{{ 'icons.breakfast' | transloco }}</title>
      <path
        d="M20.4951 10.94C20.6251 10.62 20.5951 10.71 20.6451 10.55C20.9451 9.34 20.3051 8.08 19.1451 7.62L17.1351 6.82C16.6751 6.64 16.1851 6.61 15.7251 6.7C15.6151 6.37 15.4351 6.07 15.2051 5.81C14.7251 5.29 14.0551 5 13.3551 5H10.6451C9.93512 5 9.26512 5.29 8.79512 5.81C8.55512 6.07 8.37512 6.37 8.26512 6.69C7.80512 6.6 7.31512 6.63 6.85512 6.81L4.84512 7.61C3.68512 8.07 3.04512 9.33 3.34512 10.54L3.49512 10.92C1.09512 15.55 0.995117 15.55 0.995117 16.38C0.995117 17.29 1.45512 18.12 2.23512 18.6C3.65512 19.48 4.72512 18.74 6.23512 17.99H17.7651C19.2851 18.75 19.6251 19 20.3951 19C21.3951 19 23.0051 18.23 23.0051 16.39C22.9951 15.54 22.8751 15.51 20.4951 10.94ZM20.1151 16.93L18.4351 16.09L19.5151 13.39L20.9251 16.1C21.2051 16.63 20.6351 17.19 20.1151 16.93ZM15.0851 15.99L15.7051 9.09C15.7351 8.76 16.0751 8.55 16.3851 8.67L18.3951 9.47C18.6151 9.56 18.7351 9.78 18.7051 10.01L16.3051 15.99H15.0851ZM7.67512 15.99L5.27512 10.01C5.24512 9.78 5.36512 9.56 5.58512 9.47L7.59512 8.67C7.90512 8.55 8.24512 8.75 8.27512 9.09L8.89512 15.99H7.67512ZM3.06512 16.1L4.47512 13.38L5.55512 16.09L3.87512 16.93C3.35512 17.19 2.78512 16.63 3.06512 16.1ZM10.1451 7.54C10.1151 7.23 10.3751 7 10.6451 7H13.3551C13.6251 7 13.8851 7.23 13.8551 7.54L13.0851 15.99H10.9151L10.1451 7.54Z"
        fill="black"
        fill-opacity="0.58" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'breakfast-md'">
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="none"
      [attr.aria-label]="'icons.breakfast' | transloco">
      <title>{{ 'icons.breakfast' | transloco }}</title>
      <path
        d="M20.4951 10.94C20.6251 10.62 20.5951 10.71 20.6451 10.55C20.9451 9.34 20.3051 8.08 19.1451 7.62L17.1351 6.82C16.6751 6.64 16.1851 6.61 15.7251 6.7C15.6151 6.37 15.4351 6.07 15.2051 5.81C14.7251 5.29 14.0551 5 13.3551 5H10.6451C9.93512 5 9.26512 5.29 8.79512 5.81C8.55512 6.07 8.37512 6.37 8.26512 6.69C7.80512 6.6 7.31512 6.63 6.85512 6.81L4.84512 7.61C3.68512 8.07 3.04512 9.33 3.34512 10.54L3.49512 10.92C1.09512 15.55 0.995117 15.55 0.995117 16.38C0.995117 17.29 1.45512 18.12 2.23512 18.6C3.65512 19.48 4.72512 18.74 6.23512 17.99H17.7651C19.2851 18.75 19.6251 19 20.3951 19C21.3951 19 23.0051 18.23 23.0051 16.39C22.9951 15.54 22.8751 15.51 20.4951 10.94ZM20.1151 16.93L18.4351 16.09L19.5151 13.39L20.9251 16.1C21.2051 16.63 20.6351 17.19 20.1151 16.93ZM15.0851 15.99L15.7051 9.09C15.7351 8.76 16.0751 8.55 16.3851 8.67L18.3951 9.47C18.6151 9.56 18.7351 9.78 18.7051 10.01L16.3051 15.99H15.0851ZM7.67512 15.99L5.27512 10.01C5.24512 9.78 5.36512 9.56 5.58512 9.47L7.59512 8.67C7.90512 8.55 8.24512 8.75 8.27512 9.09L8.89512 15.99H7.67512ZM3.06512 16.1L4.47512 13.38L5.55512 16.09L3.87512 16.93C3.35512 17.19 2.78512 16.63 3.06512 16.1ZM10.1451 7.54C10.1151 7.23 10.3751 7 10.6451 7H13.3551C13.6251 7 13.8851 7.23 13.8551 7.54L13.0851 15.99H10.9151L10.1451 7.54Z"
        fill="black"
        fill-opacity="0.58" />
    </svg>
  </ng-container>

  <!-- cancel -->
  <ng-container *ngSwitchCase="'cancel-sm'">
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0.5C4.47 0.5 0 4.97 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.97 15.53 0.5 10 0.5ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.91L6.41 5.5L10 9.09L13.59 5.5L15 6.91L11.41 10.5L15 14.09Z"
        fill="#C40000" />
    </svg>
  </ng-container>

  <!-- check -->
  <ng-container *ngSwitchCase="'check-sm'">
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM8 15.5L3 10.5L4.41 9.09L8 12.67L15.59 5.08L17 6.5L8 15.5Z"
        fill="#03821E" />
    </svg>
  </ng-container>

  <!-- circle -->
  <ng-container *ngSwitchCase="'circle-xxs'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none">
      <circle cx="4" cy="4" r="4" fill="currentColor" />
    </svg>
  </ng-container>

  <!-- clock -->
  <ng-container *ngSwitchCase="'clock-sm'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 1.5H9V3.5H15V1.5ZM11 14.5H13V8.5H11V14.5ZM19.03 7.89L20.45 6.47C20.02 5.96 19.55 5.48 19.04 5.06L17.62 6.48C16.07 5.24 14.12 4.5 12 4.5C7.03 4.5 3 8.53 3 13.5C3 18.47 7.02 22.5 12 22.5C16.98 22.5 21 18.47 21 13.5C21 11.38 20.26 9.43 19.03 7.89ZM12 20.5C8.13 20.5 5 17.37 5 13.5C5 9.63 8.13 6.5 12 6.5C15.87 6.5 19 9.63 19 13.5C19 17.37 15.87 20.5 12 20.5Z"
        fill="black"
        fill-opacity="0.58" />
    </svg>
  </ng-container>

  <!-- comment -->
  <ng-container *ngSwitchCase="'comment-sm'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      [attr.aria-label]="'icons.comment' | transloco">
      <title>{{ 'icons.comment' | transloco }}</title>
      <path
        d="M21.99 4C21.99 2.9 21.1 2 20 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H18L22 22L21.99 4ZM20 4V17.17L18.83 16H4V4H20ZM6 12H18V14H6V12ZM6 9H18V11H6V9ZM6 6H18V8H6V6Z"
        fill="black"
        fill-opacity="0.58" />
    </svg>
  </ng-container>

  <!-- cross -->
  <ng-container *ngSwitchCase="'cross-xs'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
        fill="black" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'cross-sm'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
        fill="black" />
    </svg>
  </ng-container>

  <!-- cutlery -->
  <ng-container *ngSwitchCase="'cutlery-sm'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.79945 13.1285L10.6295 10.2985L3.60945 3.28853C2.04945 4.84853 2.04945 7.37853 3.60945 8.94853L7.79945 13.1285ZM14.5795 11.3185C16.1095 12.0285 18.2595 11.5285 19.8495 9.93853C21.7595 8.02853 22.1295 5.28853 20.6595 3.81853C19.1995 2.35853 16.4595 2.71853 14.5395 4.62853C12.9495 6.21853 12.4495 8.36853 13.1595 9.89853L3.39945 19.6585L4.80945 21.0685L11.6995 14.1985L18.5795 21.0785L19.9895 19.6685L13.1095 12.7885L14.5795 11.3185Z"
        fill="#051039" />
    </svg>
  </ng-container>

  <!-- edit -->
  <ng-container *ngSwitchCase="'edit-sm'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z"
        fill="#051039" />
    </svg>
  </ng-container>

  <!-- info -->
  <ng-container *ngSwitchCase="'info-sm'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      [attr.aria-label]="'icons.info' | transloco">
      <path
        d="M11.5215 7H13.4785V9H11.5215V7ZM11.5215 11H13.4785V17H11.5215V11ZM12.5 2C7.09846 2 2.7146 6.48 2.7146 12C2.7146 17.52 7.09846 22 12.5 22C17.9016 22 22.2854 17.52 22.2854 12C22.2854 6.48 17.9016 2 12.5 2ZM12.5 20C8.18464 20 4.67168 16.41 4.67168 12C4.67168 7.59 8.18464 4 12.5 4C16.8154 4 20.3283 7.59 20.3283 12C20.3283 16.41 16.8154 20 12.5 20Z"
        fill="#051039" />
    </svg>
  </ng-container>

  <!-- key -->
  <ng-container *ngSwitchCase="'key-sm'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 10H12.65C11.83 7.67 9.61 6 7 6C3.69 6 1 8.69 1 12C1 15.31 3.69 18 7 18C9.61 18 11.83 16.33 12.65 14H13L15 16L17 14L19 16L23 11.96L21 10ZM7 15C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9C8.65 9 10 10.35 10 12C10 13.65 8.65 15 7 15Z"
        fill="#051039" />
    </svg>
  </ng-container>

  <!-- mail -->
  <ng-container *ngSwitchCase="'mail-sm'">
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 6.5C22 5.4 21.1 4.5 20 4.5H4C2.9 4.5 2 5.4 2 6.5V18.5C2 19.6 2.9 20.5 4 20.5H20C21.1 20.5 22 19.6 22 18.5V6.5ZM20 6.5L12 11.5L4 6.5H20ZM20 18.5H4V8.5L12 13.5L20 8.5V18.5Z"
        fill="black"
        fill-opacity="0.58" />
    </svg>
  </ng-container>

  <!-- meal -->
  <ng-container *ngSwitchCase="'meal-sm'">
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      [attr.aria-label]="'icons.meal' | transloco">
      <title>{{ 'icons.meal' | transloco }}</title>
      <path
        d="M2 19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V16H2V19ZM4 18H20V19H4V18Z"
        fill="black"
        fill-opacity="0.58" />
      <path
        d="M18.66 11.5C16.71 11.5 16.57 12.5 15.33 12.5C14.14 12.5 13.91 11.5 12 11.5C10.05 11.5 9.91 12.5 8.67 12.5C7.48 12.5 7.25 11.5 5.34 11.5C3.39 11.5 3.25 12.5 2.01 12.5V14.5C3.91 14.5 4.18 13.5 5.36 13.5C6.55 13.5 6.78 14.5 8.69 14.5C10.64 14.5 10.78 13.5 12.02 13.5C13.21 13.5 13.44 14.5 15.35 14.5C17.3 14.5 17.44 13.5 18.68 13.5C19.87 13.5 20.08 14.48 22 14.5L21.99 12.52C20.38 12.19 20.37 11.5 18.66 11.5Z"
        fill="black"
        fill-opacity="0.58" />
      <path
        d="M22 9C22.02 5 17.72 3 12 3C6.29 3 2 5 2 9V10H22V9ZM4.18 8C5.01 5.81 8.61 5 12 5C15.31 5 17.93 5.73 19.19 6.99C19.49 7.3 19.71 7.63 19.84 8H4.18Z"
        fill="black"
        fill-opacity="0.58" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'meal-md'">
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="none"
      [attr.aria-label]="'icons.meal' | transloco">
      <title>{{ 'icons.meal' | transloco }}</title>
      <path
        d="M2 19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V16H2V19ZM4 18H20V19H4V18Z"
        fill="black"
        fill-opacity="0.58" />
      <path
        d="M18.66 11.5C16.71 11.5 16.57 12.5 15.33 12.5C14.14 12.5 13.91 11.5 12 11.5C10.05 11.5 9.91 12.5 8.67 12.5C7.48 12.5 7.25 11.5 5.34 11.5C3.39 11.5 3.25 12.5 2.01 12.5V14.5C3.91 14.5 4.18 13.5 5.36 13.5C6.55 13.5 6.78 14.5 8.69 14.5C10.64 14.5 10.78 13.5 12.02 13.5C13.21 13.5 13.44 14.5 15.35 14.5C17.3 14.5 17.44 13.5 18.68 13.5C19.87 13.5 20.08 14.48 22 14.5L21.99 12.52C20.38 12.19 20.37 11.5 18.66 11.5Z"
        fill="black"
        fill-opacity="0.58" />
      <path
        d="M22 9C22.02 5 17.72 3 12 3C6.29 3 2 5 2 9V10H22V9ZM4.18 8C5.01 5.81 8.61 5 12 5C15.31 5 17.93 5.73 19.19 6.99C19.49 7.3 19.71 7.63 19.84 8H4.18Z"
        fill="black"
        fill-opacity="0.58" />
    </svg>
  </ng-container>

  <!-- meal tray -->
  <ng-container *ngSwitchCase="'mealtray-sm'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      [attr.aria-label]="'icons.meal' | transloco">
      <title>{{ 'icons.meal' | transloco }}</title>
      <path
        d="M7.79945 13.1285L10.6295 10.2985L3.60945 3.28853C2.04945 4.84853 2.04945 7.37853 3.60945 8.94853L7.79945 13.1285ZM14.5795 11.3185C16.1095 12.0285 18.2595 11.5285 19.8495 9.93853C21.7595 8.02853 22.1295 5.28853 20.6595 3.81853C19.1995 2.35853 16.4595 2.71853 14.5395 4.62853C12.9495 6.21853 12.4495 8.36853 13.1595 9.89853L3.39945 19.6585L4.80945 21.0685L11.6995 14.1985L18.5795 21.0785L19.9895 19.6685L13.1095 12.7885L14.5795 11.3185Z"
        fill="black"
        fill-opacity="0.58" />
    </svg>
  </ng-container>

  <!-- on board -->
  <ng-container *ngSwitchCase="'on-board-sm'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      [attr.aria-label]="'icons.onBoard' | transloco">
      <title>{{ 'icons.onBoard' | transloco }}</title>
      <path
        d="M5.35 6.13987C4.45 5.49987 4.23 4.25987 4.86 3.34987C5.49 2.44987 6.74 2.22987 7.65 2.85987C8.55 3.49987 8.77 4.73987 8.14 5.64987C7.5 6.54987 6.26 6.76987 5.35 6.13987ZM16 19.4999H8.93C7.45 19.4999 6.19 18.4199 5.97 16.9599L4 7.49987H2L3.99 17.2599C4.37 19.6999 6.47 21.4999 8.94 21.4999H16V19.4999ZM16.23 15.4999H11.35L10.32 11.3999C11.9 12.2899 13.6 12.9399 15.47 12.6199V10.4899C13.84 10.7999 12.03 10.2199 10.78 9.23987L9.14 7.96987C8.91 7.78987 8.65 7.66987 8.38 7.58987C8.06 7.49987 7.72 7.46987 7.39 7.52987H7.37C6.14 7.74987 5.32 8.91987 5.53 10.1399L6.88 16.0599C7.16 17.4799 8.39 18.4999 9.83 18.4999H16.68L20.5 21.4999L22 19.9999L16.23 15.4999Z"
        fill="black"
        fill-opacity="0.58" />
    </svg>
  </ng-container>

  <!-- no result -->
  <ng-container *ngSwitchCase="'no-result-xxl'">
    <svg
      width="211"
      height="205"
      viewBox="0 0 211 205"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60 126.002C76 110.501 97 110 113.5 126.002"
        stroke="#051039"
        stroke-width="5"
        stroke-linecap="round" />
      <circle
        cx="89"
        cy="89"
        r="86.5"
        stroke="#051039"
        stroke-width="5"
        stroke-linecap="round" />
      <path
        d="M152.5 148.5L208 202.465"
        stroke="#051039"
        stroke-width="5"
        stroke-linecap="round" />
      <path
        d="M46 59.5L66.5 80"
        stroke="#051039"
        stroke-width="5"
        stroke-linecap="round" />
      <path
        d="M109 59.5L129.5 80"
        stroke="#051039"
        stroke-width="5"
        stroke-linecap="round" />
      <path
        d="M46 80L66.5 59.5"
        stroke="#051039"
        stroke-width="5"
        stroke-linecap="round" />
      <path
        d="M109 80L129.5 59.5"
        stroke="#051039"
        stroke-width="5"
        stroke-linecap="round" />
    </svg>
  </ng-container>

  <!-- no data to show -->
  <ng-container *ngSwitchCase="'no-data-to-show-xxl'"> </ng-container>

  <!-- role -->
  <ng-container *ngSwitchCase="'role-sm'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM19 11C19 15.52 16.02 19.69 12 20.93C7.98 19.69 5 15.52 5 11V6.3L12 3.19L19 6.3V11ZM7.41 11.59L6 13L10 17L18 9L16.59 7.58L10 14.17L7.41 11.59Z"
        fill="#051039" />
    </svg>
  </ng-container>

  <!-- taxi -->
  <ng-container *ngSwitchCase="'taxi-sm'">
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.92 6.51C18.72 5.92 18.16 5.5 17.5 5.5H15V3.5H9V5.5H6.5C5.84 5.5 5.29 5.92 5.08 6.51L3 12.5V20.5C3 21.05 3.45 21.5 4 21.5H5C5.55 21.5 6 21.05 6 20.5V19.5H18V20.5C18 21.05 18.45 21.5 19 21.5H20C20.55 21.5 21 21.05 21 20.5V12.5L18.92 6.51ZM6.85 7.5H17.14L18.18 10.5H5.81L6.85 7.5ZM19 17.5H5V12.84L5.12 12.5H18.89L19 12.84V17.5Z"
        fill="#051039" />
      <path
        d="M7.5 16.5C8.32843 16.5 9 15.8284 9 15C9 14.1716 8.32843 13.5 7.5 13.5C6.67157 13.5 6 14.1716 6 15C6 15.8284 6.67157 16.5 7.5 16.5Z"
        fill="#051039" />
      <path
        d="M16.5 16.5C17.3284 16.5 18 15.8284 18 15C18 14.1716 17.3284 13.5 16.5 13.5C15.6716 13.5 15 14.1716 15 15C15 15.8284 15.6716 16.5 16.5 16.5Z"
        fill="#051039" />
    </svg>
  </ng-container>

  <!-- train -->
  <ng-container *ngSwitchCase="'train-sm'">
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 17C9.32843 17 10 16.3284 10 15.5C10 14.6716 9.32843 14 8.5 14C7.67157 14 7 14.6716 7 15.5C7 16.3284 7.67157 17 8.5 17Z"
        fill="#051039" />
      <path
        d="M15.5 17C16.3284 17 17 16.3284 17 15.5C17 14.6716 16.3284 14 15.5 14C14.6716 14 14 14.6716 14 15.5C14 16.3284 14.6716 17 15.5 17Z"
        fill="#051039" />
      <path
        d="M12 3C8 3 4 3.5 4 7V16.5C4 18.43 5.57 20 7.5 20L6 21.5V22H8L10 20H14L16 22H18V21.5L16.5 20C18.43 20 20 18.43 20 16.5V7C20 3.5 16 3 12 3ZM12 5C15.51 5 16.96 5.48 17.57 6H6.43C7.04 5.48 8.49 5 12 5ZM6 8H11V11H6V8ZM18 16.5C18 17.33 17.33 18 16.5 18H7.5C6.67 18 6 17.33 6 16.5V13H18V16.5ZM18 11H13V8H18V11Z"
        fill="#051039" />
    </svg>
  </ng-container>

  <!-- trash -->
  <ng-container *ngSwitchCase="'trash-sm'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
        fill="#051039" />
    </svg>
  </ng-container>

  <!-- user -->
  <ng-container *ngSwitchCase="'user-sm'">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.51 9.99C12.51 8.34 11.16 6.99 9.51 6.99C7.86 6.99 6.51 8.34 6.51 9.99C6.51 11.64 7.86 12.99 9.51 12.99C11.16 12.99 12.51 11.64 12.51 9.99ZM9.51 10.99C8.96 10.99 8.51 10.54 8.51 9.99C8.51 9.44 8.96 8.99 9.51 8.99C10.06 8.99 10.51 9.44 10.51 9.99C10.51 10.54 10.06 10.99 9.51 10.99ZM16.01 12.99C17.12 12.99 18.01 12.1 18.01 10.99C18.01 9.88 17.12 8.99 16.01 8.99C14.9 8.99 14 9.88 14.01 10.99C14.01 12.1 14.9 12.99 16.01 12.99ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM5.85 17.11C6.53 16.57 8.12 16 9.51 16C9.58 16 9.66 16.01 9.74 16.01C9.98 15.37 10.41 14.72 11.04 14.15C10.48 14.05 9.95 13.99 9.51 13.99C8.21 13.99 6.12 14.44 4.78 15.42C4.28 14.38 4 13.22 4 11.99C4 7.58 7.59 3.99 12 3.99C16.41 3.99 20 7.58 20 11.99C20 13.19 19.73 14.33 19.25 15.36C18.25 14.77 16.89 14.49 16.01 14.49C14.49 14.49 11.51 15.3 11.51 17.19V19.97C9.24 19.84 7.22 18.76 5.85 17.11Z"
        fill="#051039" />
    </svg>
  </ng-container>
</ng-container>
