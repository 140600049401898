import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { OKTA_AUTH } from "@okta/okta-angular";

export const authenticatedGuard: CanActivateFn = async (route, state) => {
  const oktaAuth = inject(OKTA_AUTH);
  const router = inject(Router); // Injecte le Router pour la redirection

  const isAuthenticated = await oktaAuth.isAuthenticated();
  if (isAuthenticated) {
    return true;
  } else {
    oktaAuth.signInWithRedirect().catch(error => {
      if (error.error === 'access_denied') {
        router.navigate(['/access-error']);
      }
    });
    return false;
  }
};

