export const environment = {
  production: true,
  favicon: 'favicon-dev.svg',
  api_url: `${window.location.origin}/api/bff`,
  enabledModule: [
    'shuttle',
    'taxi-and-pickup',
    'meal-tray',
    'compensation',
    'settings',
  ],
  numberOfFutureDaysToSelectAPeqOrderDate: 7,
  numberOfPreviousMonthsToSelectAPeqOrderDate: 3,
  numberOfFutureDaysToSelectATaxiOrderDate: 21,
  numberOfPreviousMonthsToSelectAShuttleOrderDate: 3,
  numberOfFutureDaysToSelectAShuttleOrderDate: 21,
  okta: {
    issuer: 'https://dev-99778458.okta.com/oauth2/ausmzyocihHsiZZpO5d7',
    clientId: '0oaik3fyxoRuUC78a5d7',
    redirectUri: `${window.location.origin}/callback`,
  },
  debug: {
    enable_date_out_of_range: true,
    enable_send_order_from_peqorder_if_before_current_date: true,
    enable_send_unit_order_from_peqorder_if_not_current_date: false,
    enable_refresh_order_from_peqorder_if_before_current_date: true,
    enable_refresh_order_from_peqorder_if_same_current_date: true,
  },
};
